import { Decimal, Klass } from '#backend/lib/transforms'
import Big from "big.js"

export class Dealer {
  id: number
  name: string
  phone: string

  @Decimal() balance!: Big
  @Klass(Date) last_sign_in_at: Date
}
