import { Klass } from '#backend/lib/transforms'
import { Site } from './Site'
import { Agent } from './Agent'
import { Province } from './Province'

export class Territory {
  id!: number
  name!: string

  @Klass(Agent) agent!: Agent
  @Klass(Site) site!: Site
  @Klass(Province) province!: Province
}
